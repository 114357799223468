/**
 * adds filter group to the listview
 * @param {Object} data filter group to be added
 * @param {Array} data.filters filters within the group
 * @param {string} data.operator operator (and/or) for filters
 * @param {string} data.field field the filter group operates on
 * @param {string} data.fieldModule filter group module
 * @param {string} data.relationship filter group relationship
 */
export default function addFilter(data) {
    const filterGroup = {
        'filters': data.filters,
        'operator': data.operator,
        'field': data.field,
        'fieldModule': data.fieldModule,
        'relationship': data.relationship,
        // when we want filter field that is on another module (relationship)
        'fieldRel': data.fieldRel,
    };

    const akce = {
        type: 'ADDTOFILTER',
        listViewPrefix: data.prefix,
        filter: filterGroup,
    };
    this.dataSetCustom(akce);

    let list = null;

    if (this.getViewName() === 'import') {
        list = this.setImportListFilter(data);
    }

    if (data.reload == true) {
        this.reloadList(0, 1, data.prefix, null, null, list);
    }
}
